
import {createAlchemyWeb3} from "@alch/alchemy-web3";
import {getProof} from "./merkletree"

const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS;
const ALCHEMY_KEY = process.env.ALCHEMY_KEY;
const web3 = createAlchemyWeb3(ALCHEMY_KEY)

const contract = require("../artifacts/contracts/MeetBob.sol/MeetBob.json")
const meetBob = new web3.eth.Contract(contract.abi, CONTRACT_ADDRESS)

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });

            return {
                address: addressArray[0],
                error: null
            }
        } catch (err) {
            return {
                address: null,
                error: err
            }
        }
    } else {
        return {
            address: null,
            error: "Metamask not installed"
        }
    }
}

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    error: null,
                }
            } else {
                return {
                    address: null,
                    error: "",
                }
            }
        } catch (err) {
            return {
                address: null,
                error: err,
            }
        }
    } else {
        return {
            address: null,
            error: "Metamask not installed",
        }
    }
}

export const mintNFT = async (num) => {
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest');

    const transactionParameters = {
        'to': CONTRACT_ADDRESS,
        'from': window.ethereum.selectedAddress,
        'data': meetBob.methods.publicSale(num).encodeABI(),
        'value': web3.utils.toHex(Math.pow(10, 17) * num),
        'nonce': web3.utils.toHex(nonce)
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
}

export const mintNFTPreSale = async (num) => {
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest');
    const proof = getProof(window.ethereum.selectedAddress)

    const transactionParameters = {
        'to': CONTRACT_ADDRESS,
        'from': window.ethereum.selectedAddress,
        'data': meetBob.methods.earlyAccessSale(num, proof).encodeABI(),
        'value': web3.utils.toHex(8 * Math.pow(10, 16) * num),
        'nonce': web3.utils.toHex(nonce)
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }

}