import {connectWallet, getCurrentWalletConnected} from "./scripts/interact"

window.toggleMenu = (e) => {
    document.getElementById('hamburger').classList.toggle('is-active');
    document.getElementById('menu').classList.toggle('is-active');
    document.body.classList.toggle('unscrollable');
}

let prevScrollpos = window.pageYOffset;
window.onscroll = () => {
    let topPosition = document.documentElement.scrollTop || document.body.scrollTop;

    // Dirty iOS safari fix
    for (const video of document.getElementsByClassName('intro-video')) {
        video.style.opacity = topPosition < 2000 ? 1 : 0;
    }

    if (window.matchMedia("screen and (orientation: portrait)").matches) {
        return;
    }

    // Menu animation

    const navbar = document.getElementById("navbar");
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        navbar.style.top = "0";
    } else {
        navbar.style.top = "-200px";
    }
    prevScrollpos = currentScrollPos;

    if (window.pageYOffset > 0) {
        navbar.classList.add('navbar-background');
    } else {
        navbar.classList.remove('navbar-background');
    }

    // Cloud animation
    const cloudStart = document.querySelector(".cloud-container").getBoundingClientRect().top;
    for (const el of document.getElementsByClassName('cloud-far-left')) {
        const left = -700 - (window.innerHeight - cloudStart)/2500 * 200;
        el.style.left = left.toString() + 'px';
    };
    for (const el of document.getElementsByClassName('cloud-far-right')) {
        const right = -700 - (window.innerHeight - cloudStart)/2500 * 200;
        el.style.right = right.toString() + 'px';
    };
    for (const el of document.getElementsByClassName('cloud-left')) {
        const left = -60 - (window.innerHeight - cloudStart)/2500 * 400;
        el.style.left = left.toString() + 'px';
    };
    for (const el of document.getElementsByClassName('cloud-right')) {
        const right = -60 - (window.innerHeight - cloudStart)/2500 * 400;
        el.style.right = right.toString() + 'px';
    };
}

window.addEventListener('DOMContentLoaded', (event) => {
    const faviconB = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmVkYTJiM2ZhYywgMjAyMS8xMS8xNy0xNzoyMzoxOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjcyRTk1Mzk2MDM4MTFFQ0E4NTZFN0U0NEFEMDczNTAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjcyRTk1M0E2MDM4MTFFQ0E4NTZFN0U0NEFEMDczNTAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNzJFOTUzNzYwMzgxMUVDQTg1NkU3RTQ0QUQwNzM1MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNzJFOTUzODYwMzgxMUVDQTg1NkU3RTQ0QUQwNzM1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Po8MMtsAAAAGUExURQAAAP///6XZn90AAAACdFJOU/8A5bcwSgAAAE1JREFUeNpiYCQAGIhRwIAFEFQAV4JbAQNBBQw0UAB3OLIANgWMVFNAsS8oDWpGusQFBQGFLEIjBQw4FGD1BknepHqiJSE2KcvdAAEGAI2LAbZWyS0zAAAAAElFTkSuQmCC";
    const faviconO = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmVkYTJiM2ZhYywgMjAyMS8xMS8xNy0xNzoyMzoxOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjcyRTk1MzU2MDM4MTFFQ0E4NTZFN0U0NEFEMDczNTAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjcyRTk1MzY2MDM4MTFFQ0E4NTZFN0U0NEFEMDczNTAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNzJFOTUzMzYwMzgxMUVDQTg1NkU3RTQ0QUQwNzM1MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNzJFOTUzNDYwMzgxMUVDQTg1NkU3RTQ0QUQwNzM1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsGg0MMAAAAGUExURQAAAP///6XZn90AAAACdFJOU/8A5bcwSgAAAGtJREFUeNrEkzkOwDAIBIf/fzpNrJhjBXGKbAeMkFgAa4QLbgmATQVAUARAEKj6QtD1DQBN4OuWQvZEntgBtWUPEBxeWcoGR0Da4g/AhylsCrRWU53nbN39wUhifpM18vJx+tcbPG+tS4ABADANAgUOlO/gAAAAAElFTkSuQmCC";
    const favicon = document.querySelector('[rel=icon]');
    let current = 'O';
    setInterval(() => {
        if (current === 'B') {
            favicon.href = faviconO;
            current = 'O';
        } else {
            favicon.href = faviconB;
            current = 'B';
        }
    }, 500);

    setTimeout(() => {
        document.getElementById("navbar").style.top = "0px";
        document.getElementsByClassName('hamburger')[0].style.top = "20px";
    })

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.8
    };

    const upObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fadeInUp');
            }
        });
    }, observerOptions);
    
    for (const target of document.getElementsByClassName('fade-up-target')) {
        upObserver.observe(target);
    };

    const leftObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fadeInLeft');
            }
        });
    }, observerOptions);

    for (const target of document.getElementsByClassName('fade-left-target')) {
        leftObserver.observe(target);
    };

    const rightObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fadeInRight');
            }
        });
    }, observerOptions);

    for (const target of document.getElementsByClassName('fade-right-target')) {
        rightObserver.observe(target);
    };
});

window.onConnectClick = async (e) => {
    if (!window.ethereum) {
        window.open("https://metamask.io/");
    }
    const res = await connectWallet();
    if (res.address) {
        document.getElementById("address-string").innerHTML = formatAddress(res.address);
        document.getElementById("address-string-mobile").innerHTML = formatAddress(res.address);
    }
}

const formatAddress = (address) => {
    return `${address.substring(0, 5)}...${address.substring(38, 42)}`
}

window.addEventListener('load', async (event) => {
    if (!window.ethereum) {
        document.getElementById("address-string").innerHTML = "Install metamask";
        document.getElementById("address-string-mobile").innerHTML = "Install metamask";
        retrurn;
    }
    const res = await getCurrentWalletConnected();
    if (res.address) {
        document.getElementById("address-string").innerHTML = formatAddress(res.address);
        document.getElementById("address-string-mobile").innerHTML = formatAddress(res.address);
    }
})

window.ethereum?.on('accountsChanged', async (accounts) => {
    // Handle the new accounts, or lack thereof.
    // "accounts" will always be an array, but it can be empty.
    if (accounts.length > 0) {
        document.getElementById("address-string").innerHTML = formatAddress(accounts[0]);
        document.getElementById("address-string-mobile").innerHTML = formatAddress(accounts[0]);
    } else {
        document.getElementById("address-string").innerHTML = "Connect";
        document.getElementById("address-string-mobile").innerHTML = "Connect";
    }
});

window.ethereum?.on('chainChanged', (chainId) => {
    // Handle the new chain.
    // Correctly handling chain changes can be complicated.
    // We recommend reloading the page unless you have good reason not to.
    window.location.reload();
});
